import React from 'react';

const sections = [
	{
		name: 'Consumer',
		applications: [
			{
				name: 'Hand Sanitizer',
				image: '/images/applications/hand_sanitizer.jpg',
			},
			{
				name: 'Sanitizing Wipes',
				image: '/images/applications/sanitizing_wipes.jpg',
			},
			{
				name: 'Sanitizing Spray',
				image: '/images/applications/sanitizing_spray.jpg',
			},
			{
				name: 'HVAC Impregnated Air Filter',
				image: '/images/applications/ac.jpg',
			},
			{
				name: 'Coated Face Masks',
				image: '/images/applications/face_mask.jpg',
			},
		],
	},
	{
		name: 'Healthcare',
		applications: [
			// TODO:
			{
				name: 'Catheters',
				image: '/images/applications/catheter.jpg',
			},
			{
				name: 'Syringes',
				image: '/images/applications/syringe.jpg',
			},
			// TODO:
			{
				name: 'Ventilators',
				image: '/images/applications/ventilator.jpg',
			},
			// TODO:
			{
				name: 'Vascular Grafts',
				image: '/images/applications/vascular_graft.jpg',
			},
			{
				name: 'Many Others',
				image: '/images/applications/other_healthcare.jpg',
			},
		],
	},
	{
		name: 'Military',
		applications: [
			// TODO:
			{
				name: 'IV Rapid Infusion',
				image: '/images/applications/rapid_iv.jpg',
			},
			// TODO:
			{
				name: 'Battlefield Patches',
				image: '/images/applications/battlefield_patch.jpg',
			},
			// TODO:
			{
				name: 'Battle Wound Irrigation',
				image: '/images/applications/wound_irrigation.jpg',
			},
			// TODO:
			{
				name: 'Dialysis Patches',
				image: '/images/applications/dialysis_patch.jpg',
			},
			// TODO:
			{
				name: 'Dialzyers',
				image: '/images/applications/dialyzer.jpeg',
			},
		],
	},
	{
		name: 'Commercial',
		applications: [
			{
				name: 'Grocery',
				image: '/images/applications/grocery.jpg',
			},
			{
				name: 'Restaurants',
				image: '/images/applications/restaurant.jpg',
			},
			{
				name: 'Gas Stations',
				image: '/images/applications/gas.jpg',
			},
			{
				name: 'Airports',
				image: '/images/applications/airport.jpg',
			},
			{
				name: 'Many Others',
				image: '/images/applications/city.jpg',
			},
		],
	},
];

const Application = ({ category, name, imageSrc }: any) => {
	return (
		<div className={`ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6 ${category}`}>
			{/* featured-imagebox */}
			<div className='featured-imagebox featured-imagebox-portfolio ttm-portfolio-box-view1'>
				{/* ttm-box-view-overlay */}
				<div
					style={{ height: 350, overflow: 'hidden' }}
					className='ttm-box-view-overlay ttm-portfolio-box-view-overlay'
				>
					{/* featured-thumbnail */}
					<div className='featured-thumbnail' style={{ display: 'flex', justifyContent: 'center' }}>
						<img
							style={{ height: 350, width: 'auto' }}
							src={imageSrc}
							alt={`${category} - ${name}`}
						/>
					</div>
					{/* featured-thumbnail end*/}
					<div className='ttm-box-view-content-inner'>
						<div className='featured-content featured-content-portfolio'>
							<div className='featured-title'>
								<h5>
									<a>{name}</a>
								</h5>
							</div>
							<span className='category'>{category}</span>
						</div>
					</div>
				</div>
				{/* ttm-box-view-overlay end*/}
			</div>
			{/* featured-imagebox */}
		</div>
	);
};

export const PotentialApplications = () => {
	return (
		<section className='ttm-row portfolio-section clearfix'>
			<div className='container'>
				{/* row */}
				<div className='row'>
					<div className='col-lg-6 col-md-8 col-sm-10 m-auto'>
						{/* section-title */}
						<div className='section-title with-sep title-style-center_text'>
							<div className='title-header'>
								<h5>HOW WE CAN HELP</h5>
								<h2 className='title'>Potential Applications</h2>
							</div>
							<div className='title-desc'>
								SaniShield's unique formulation allows for a large variety of potential
								applications:
							</div>
						</div>
						{/* section-title end */}
					</div>
				</div>
				{/* row end */}
				<div className='row'>
					<div className='col-lg-12'>
						<div className='ttm-tabs tabs-style-01'>
							<ul className='tabs d-md-flex portfolio-filter'>
								<li className='tab active'>
									<a href='#' data-filter='.Consumer'>
										Consumer
									</a>
								</li>
								<li className='tab'>
									<a href='#' data-filter='.Healthcare'>
										Healthcare
									</a>
								</li>
								<li className='tab'>
									<a href='#' data-filter='.Military'>
										Military / Renal
									</a>
								</li>
								<li className='tab'>
									<a href='#' data-filter='.Commercial'>
										Commercial
									</a>
								</li>
							</ul>
							<div className='content-tab'>
								{/* content-inner */}
								<div className='row multi-columns-row ttm-boxes-spacing-15px isotope-project'>
									{sections.map((category) => {
										return category.applications.map((application) => (
											<Application
												key={`${category.name}-${application.name}`}
												category={category.name}
												name={application.name}
												imageSrc={application.image}
											/>
										));
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
