import React from 'react';
import { Header, BackToTop, Footer, SEO } from '@components';
import { Layout } from '@layouts';
import { PotentialApplications, Testimonials, Hero } from '@sections';
import { config } from '@config';

/**
 * The homepage of the site
 */
const HomePage = () => {
	return (
		<Layout>
			<SEO
				slug='index'
				title='SaniShield | Sanitizing Solutions'
				description='SaniShield is committed to making the world safer and cleaner. Our unique santizing formula is more effective, safer, and provides deeper/longer lasting protection than other sanitizing solutions.'
			/>
			<Header />

			<Hero />

			{/* site-main start */}
			<div className='site-main'>
				{/* features-section */}
				<section className='ttm-row services-section ttm-bgcolor-skincolor bg-img6 ttm-bg ttm-bgimage-yes clearfix'>
					<div className='ttm-row-wrapper-bg-layer ttm-bg-layer' />
					<div className='container'>
						{/* container */}
						<div className='row'>
							<div className='col-lg-6 col-md-8 col-sm-9 m-auto'>
								{/* section-title */}
								<div className='section-title with-sep title-style-center_text'>
									<div className='title-header'>
										<h5>A BETTER SOLUTION</h5>
										<h2 className='title'>The Next Level Sanitizer</h2>
									</div>
									<div className='title-desc'>
										SaniShield's unique sanitizer solution provides superior protection against
										everyday bacteria and viruses.
									</div>
								</div>
								{/* section-title end */}
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-4 col-md-4 col-sm-6'>
								{/* featured-icon-box */}
								<div className='featured-icon-box icon-align-top-content style5'>
									<div className='featured-icon'>
										<div className='ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg ttm-icon_element-style-round'>
											<i className='flaticon-lab' />
										</div>
									</div>
									<div className='featured-content'>
										<div className='featured-title'>
											<h5>
												More
												<br />
												Efficacious
											</h5>
										</div>
									</div>
								</div>
								{/* featured-icon-box end*/}
							</div>
							<div className='col-lg-4 col-md-4 col-sm-6'>
								{/* featured-icon-box */}
								<div className='featured-icon-box icon-align-top-content style5'>
									<div className='featured-icon'>
										<div className='ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg ttm-icon_element-style-round'>
											<i className='flaticon-test' />
										</div>
									</div>
									<div className='featured-content'>
										<div className='featured-title'>
											<h5>
												100%
												<br />
												Safe
											</h5>
										</div>
									</div>
								</div>
								{/* featured-icon-box end*/}
							</div>
							<div className='col-lg-4 col-md-4 col-sm-12'>
								{/* featured-icon-box */}
								<div className='featured-icon-box icon-align-top-content style5'>
									<div className='featured-icon'>
										<div className='ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg ttm-icon_element-style-round'>
											<i className='flaticon-24-hours' />
										</div>
									</div>
									<div className='featured-content'>
										<div className='featured-title'>
											<h5>
												Longer-Lasting
												<br />
												Protection
											</h5>
										</div>
									</div>
								</div>
								{/* featured-icon-box end*/}
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-12'>
								<div className='mt-25 pb-55 res-991-mt-15 res-991-pb-0 text-center' />
							</div>
						</div>
					</div>
				</section>
				{/* features-section */}
				{/* fid-section */}
				<div className='ttm-row fid-section mt_80 res-991-mt-0 z-index-1 clearfix'>
					<div className='container'>
						{/* row */}
						<div className='row'>
							<div className='col-lg-4 offset-lg-4 col-md-6 offset-md-3'>
								<div className='ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-white'>
									<div className='ttm-col-wrapper-bg-layer ttm-bg-layer' />
									<div className='layer-content'>
										<div className='row'>
											{/* ttm-fid */}
											<div className='col-lg-12'>
												<div className='ttm-fid inside ttm-fid-with-icon ttm-fid-view-lefticon style2'>
													<div className='ttm-fid-icon-wrapper ttm-textcolor-skincolor'>
														<i className='ti ti-cup' />
													</div>
													<div className='ttm-fid-contents'>
														<h4 className='ttm-fid-inner'>
															100 <sub>x</sub>
														</h4>
														<h3 className='ttm-fid-title'>More Effective</h3>
													</div>
												</div>
											</div>
											{/* ttm-fid end */}
										</div>
										{/* row end */}
									</div>
									{/* ttm-client end */}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* fid-section end */}

				{/* procedure-section */}
				<section className='ttm-row procedure-section-2 ttm-bgcolor-grey bg-img9 ttm-bg ttm-bgimage-yes z-index_1 mt_100 res-991-mt-0 clearfix'>
					<div className='ttm-row-wrapper-bg-layer ttm-bg-layer' />
					<div className='container'>
						{/* row */}
						<div className='row'>
							<div className='col-lg-6 col-md-8 col-sm-9 m-auto'>
								{/* section-title */}
								<div className='section-title with-sep title-style-center_text'>
									<div className='title-header'>
										<h5>WELL TESTED</h5>
										<h2 className='title'>The Test Results</h2>
									</div>
									<div className='title-desc'>
										Tests were conducted by Analytical Research Laboratories. The test results are
										conclusive and SaniShield is superior.
									</div>
								</div>
								{/* section-title end */}
							</div>
						</div>
						{/* row end */}
						<div className='row'>
							{/* row */}
							<div className='col-md-4'>
								{/* featured-imagebox */}
								<div className='featured-icon-box icon-align-top-content text-center style4'>
									<div className='featured-icon'>
										<div className='ttm-icon ttm-icon_element-fill ttm-icon_element-color-white ttm-icon_element-size-md ttm-icon_element-style-rounded'>
											<i className='flaticon-lab-2' />
											<span className='fea-number'>01</span>
										</div>
									</div>
									<div className='featured-content'>
										<div className='featured-title'>
											<h5>Testing</h5>
										</div>
										<div className='featured-desc'>
											<p>
												<strong>Coagulation Testing</strong> - Activated Partial
												Thromboplastin-DADEACTIN FSL-Sysmex CA-155 Instrument
											</p>
										</div>
									</div>
									<div className='arrow'>
										<img src='images/arrow-1.png' alt='Arrow forward' />
									</div>
								</div>
								{/* featured-imagebox end*/}
							</div>
							<div className='col-md-4'>
								{/* featured-imagebox */}
								<div className='featured-icon-box icon-align-top-content text-center style4'>
									<div className='featured-icon'>
										<div className='ttm-icon ttm-icon_element-fill ttm-icon_element-color-white ttm-icon_element-size-md ttm-icon_element-style-rounded'>
											<i className='flaticon-healthcare-and-medical-1' />
											<span className='fea-number'>02</span>
										</div>
									</div>
									<div className='featured-content'>
										<div className='featured-title'>
											<h5>Analysis</h5>
										</div>
										<div className='featured-desc'>
											<p>
												At a pH less than 5.0, SaniShield is capable of reducing test microorganism
												from a concentration of 10 to the 8th colony forming units to{' '}
												<strong>near sterility</strong> in less than an hour
											</p>
										</div>
									</div>
									<div className='arrow flip-arrow'>
										<img src='images/arrow-2.png' alt='Arrow forward' />
									</div>
								</div>
								{/* featured-imagebox end*/}
							</div>
							<div className='col-md-4'>
								{/* featured-imagebox */}
								<div className='featured-icon-box icon-align-top-content text-center style4'>
									<div className='featured-icon'>
										<div className='ttm-icon ttm-icon_element-fill ttm-icon_element-color-white ttm-icon_element-size-md ttm-icon_element-style-rounded'>
											<i className='flaticon-laboratory-3' />
											<span className='fea-number'>03</span>
										</div>
									</div>
									<div className='featured-content'>
										<div className='featured-title'>
											<h5>Conclusion</h5>
										</div>
										<div className='featured-desc'>
											<p>
												SaniShield is estimated to be <strong>100x</strong> more efficacious than
												standard sanitizers
											</p>
										</div>
									</div>
								</div>
								{/* featured-imagebox end*/}
							</div>
						</div>
					</div>
				</section>
				{/* procedure-section end */}

				{/* introduction-section */}
				<section className='ttm-row introduction-section clearfix'>
					<div className='container'>
						<div className='row'>
							<div className='col-xl-6 col-lg-6 col-xs-12 order-1'>
								<div className='mb-25 pt-50 mr_40 res-991-pt-0 res-991-mr-0'>
									{/* section title */}
									<div className='section-title'>
										<div className='title-header'>
											<h5>HOW DOES IT WORK</h5>
											<h2 className='title'>The Unique Formulation</h2>
										</div>
										<div className='title-desc'>
											Our scientists and developers all have over 20+ years of experience in the
											medical/pharma space and have used that experience to create an out-of-the box
											formulation.
										</div>
									</div>
									{/* section title end */}
									<div className='row' style={{ marginBottom: 10 }}>
										<div className='col-sm-6'>
											{/* featured-icon-box */}
											<div className='featured-icon-box style1 icon-align-before-heading'>
												<div className='featured-icon'>
													<div className='ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md'>
														<i className='flaticon-lab-1' />
													</div>
												</div>
												<div className='featured-content'>
													<div className='featured-title'>
														<h5>
															Regarded as safe
															<br />
															by the FDA
														</h5>
													</div>
													<div className='featured-desc'>
														<p>
															The primary ingredients of SaniShield are all considered GRAS
															(Generally Regarded as Safe) by the FDA.
														</p>
													</div>
												</div>
											</div>
											{/* featured-icon-box end*/}
										</div>
										<div className='col-sm-6'>
											{/* featured-icon-box */}
											<div className='featured-icon-box style1 icon-align-before-heading'>
												<div className='featured-icon'>
													<div className='ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md'>
														<i className='flaticon-dna' />
													</div>
												</div>
												<div className='featured-content'>
													<div className='featured-title'>
														<h5>
															Ingredient synergy <br />
															boosts effects
														</h5>
													</div>
													<div className='featured-desc'>
														<p>
															The combination of ingredients work together to create a more
															efficacious sanitizer that's shown superior results.
														</p>
													</div>
												</div>
											</div>
											{/* featured-icon-box end*/}
										</div>
									</div>
									<div className='row'>
										<div className='col-lg-12'>
											<div className='mb-15'>
												<p>
													By incorporating a penetration enhancer, SaniShield is able to penetrate
													the dermal layer of humans, providing more effective and longer-lasting
													eradication of microbes.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-xl-1 col-lg-1 col-xs-1 order-lg-2 order-md-3 order-3'>
								{/* featured-icon-box */}
								<div className='featured-icon-box style1 icon-align-top-content ttm-bgcolor-skincolor'>
									<div className='featured-icon-box-inner-1'>
										<div className='featured-icon-box-inner-2'>
											<div className='featured-icon'>
												<div className='ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-lg'>
													<i className='flaticon-healthcare-and-medical' />
												</div>
											</div>
											<div className='featured-content'>
												<div className='featured-title'>
													<h5>40+ Years</h5>
												</div>
												<div className='featured-desc'>
													<p>Combined Experience in Medical Industry</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* featured-icon-box end*/}
							</div>
							<div className='col-xl-5 col-lg-5 col-xs-12 order-sm-2 order-md-2 order-2'>
								<div className='ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-white pt-10 pl-10 pr-10 pb-10 z-index-1'>
									<div className='ttm-col-wrapper-bg-layer ttm-bg-layer' />
									<div className='layer-content'>
										{/* ttm_single_image-wrapper */}
										<div className='ttm_single_image-wrapper text-center'>
											<img
												className='img-fluid'
												src='/images/broken-section/scientists.jpg'
												alt='Scientists reviewing formula'
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* row end */}
					</div>
				</section>
				{/* introduction-section end */}

				{/* broken-section */}
				<section className='ttm-row broken-section-1 bg-layer clearfix'>
					<div className='container'>
						{/* row */}
						<div className='row'>
							<div className='col-lg-6 col-md-12'>
								{/* col-img-img-one */}
								<div className='col-bg-img-one ttm-col-bgimage-yes ttm-bg ttm-left-span '>
									<div className='ttm-col-wrapper-bg-layer ttm-bg-layer bg-img7' />
									<div className='layer-content' />
								</div>
								{/* col-img-bg-img-one end*/}
							</div>
							<div className='col-lg-6 col-md-12'>
								<div className='ttm-bg ttm-col-bgcolor-yes ttm-right-span ttm-bgcolor-grey spacing-1'>
									<div className='ttm-col-wrapper-bg-layer ttm-bg-layer' />
									<div className='layer-content'>
										<div className='ttm-col-bgcolor-yes ttm-bgcolor-white col-bg-img-three ttm-col-bgimage-yes ttm-bg ttm-right-span spacing-2'>
											<div className='ttm-col-wrapper-bg-layer ttm-bg-layer'>
												<div className='ttm-col-wrapper-bg-layer-inner' />
											</div>
											<div className='layer-content'>
												{/* section title */}
												<div className='section-title'>
													<div className='title-header'>
														<h5>A Breakthrough In Sanitization</h5>
														<h2 className='title'>SaniShield Was Developed for the New Normal</h2>
													</div>
													<div className='title-desc'>
														In today's world, with Coronavirus (COVID-19) and other imminent
														pandemics, the importance of cleanliness and sanitization in every day
														life has never been more important. SaniShield provides the added
														protection you need to feel safe.
													</div>
												</div>
												{/* section title end */}
												<div className='row'>
													<div className='col-sm-12'>
														<ul className='ttm-list ttm-list-style-icon ttm-textcolor-darkgrey'>
															<li>
																<i className='fa fa-minus' />
																<div className='ttm-list-li-content'>100x more effective</div>
															</li>
															<li>
																<i className='fa fa-minus' />
																<div className='ttm-list-li-content'>
																	Provides a deeper, longing-lasting protection
																</div>
															</li>
															<li>
																<i className='fa fa-minus' />
																<div className='ttm-list-li-content'>Absorbs easily</div>
															</li>
															<li>
																<i className='fa fa-minus' />
																<div className='ttm-list-li-content'>
																	Less skin irritation and dryness
																</div>
															</li>
															<li>
																<i className='fa fa-minus' />
																<div className='ttm-list-li-content'>Fast acting</div>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* row end */}
					</div>
				</section>
				{/* broken-section end */}

				{/* <Testimonials /> */}

				<PotentialApplications />
			</div>

			<Footer />

			<BackToTop />
		</Layout>
	);
};

export default HomePage;
