import React from 'react';
import styled from 'styled-components';

/**
 * Defines expected props for this component
 */
interface IHero {}

/*
|--------------------------------------------------------------------------
| START STYLED COMPONENTS
|--------------------------------------------------------------------------
*/
const HeroWrapper = styled.section.attrs(() => ({
	className: 'ttm-custom-row',
}))`
	width: 100%;
	font-weight: 700;
	font-family: Poppins, sans-serif;
	padding: 150px 0px !important;
	background-image: url('/images/hero/scientist_hero.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;

	@media (max-width: 767px) {
		background-position: -250px center;
	}
`;

const MainHeader = styled.h1`
	z-index: 13;
	font-family: Poppins, sans-serif;
	line-height: 60px;
	letter-spacing: 0px;
	font-weight: 700;
	color: rgb(3, 27, 78);
	max-width: 100%;

	@media (max-width: 767px) {
		font-size: 45px;
	}
`;

const TextContainer = styled.div`
	max-width: 55%;

	@media (max-width: 731px) {
		text-align: center;
		max-width: none;
	}
`;

const Caption = styled.p`
	color: #616c93;
`;
/*
|--------------------------------------------------------------------------
| END STYLED COMPONENTS
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| START REACT
|--------------------------------------------------------------------------
*/
/**
 * The main website hero
 */
export const Hero = (props: IHero) => {
	return (
		<HeroWrapper>
			<div className='container'>
				<TextContainer>
					<MainHeader>
						Cleanliness is the <span className='ttm-textcolor-skincolor'>New Normal</span>
					</MainHeader>
					<Caption>
						With the appearance of Coronavirus (COVID-19), cleanliness and sanitization has become a
						necessity. SaniShield provides a unique and effective sanitizing solution to combat
						disease.
					</Caption>
				</TextContainer>
			</div>
		</HeroWrapper>
	);
};
/*
|--------------------------------------------------------------------------
| END REACT
|--------------------------------------------------------------------------
*/
